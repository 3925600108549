import { createApp } from 'vue';
import App from './App.vue';
import { router } from './router';
import 'vant/es/toast/style'
import 'vant/es/dialog/style'
import moment from 'moment';
import axios from 'axios';
import { Area } from 'vant';
import './global.css';

// Vue.config.ignoredElements = ["wx-open-subscribe"];
const app = createApp(App);
moment.suppressDeprecationWarnings = true;
moment.locale('zh-cn', {
  weekdays: '星期日_星期一_星期二_星期三_星期四_星期五_星期六'.split('_'),
  weekdaysShort: '周日_周一_周二_周三_周四_周五_周六'.split('_'),
  weekdaysMin: '日_一_二_三_四_五_六'.split('_'),
})
app.config.globalProperties.$moment = moment
app.config.globalProperties.$axios = axios
// app.config.ignoredElements = [/^wx-/];
// app.config.compilerOptions.isCustomElement = (tag) => tag.startsWith('wx-');
// axios.defaults.baseURL = "http://47.103.92.193:86/"
axios.defaults.baseURL = "https://api.consult.uponup.cn"
app.use(router);
app.use(Area);
app.mount('#app');


const globalColor = "#2F52CC";