import { ref } from 'vue';
export default {
  setup() {
    const show = ref(false);
    const offset = ref({
      x: -24,
      y: window.innerHeight - 200
    });
    const showPopup = () => {
      show.value = true;
    };
    function ss() {
      console.log("sds");
    }
    return {
      offset,
      show,
      showPopup
    };
  },
  methods: {
    onClick: function () {
      /// 弹出客服
      this.qrcode = localStorage.getItem('com.consult.serviceQRCode');
      this.tips = JSON.parse(localStorage.getItem('com.consult.connectTips'));
      this.showPopup();
    }
  }
};