import { createRouter, createWebHashHistory } from 'vue-router';
import axios from 'axios';

const routes = [
  {
    name: 'notFound',
    path: '/:path(.*)+',
    redirect: {
      name: 'home',
    },
  },
  {
    name: 'home',
    path: '/home',
    component: () => import('./view/home'),
    meta: {
      title: '主页',
    },
  },
  {
    name: 'consult',
    path: '/consult',
    component: () => import('./view/consult'),
    meta: {
      title: '咨询',
    },
  },
  {
    name: 'consultsearch',
    path: '/consultsearch',
    component: () => import('./view/consultsearch/consultsearch.vue'),
    meta: {
      title: '咨询师搜索',
    },
  },
  {
    name: 'consultdetail',
    path: '/consultdetail',
    component: () => import('./view/consultdetail/consultdetail.vue'),
    meta: {
      title: '咨询师详情',
    },
  },
  {
    name: 'consultappointment',
    path: '/consultappointment',
    component: () => import('./view/consultappointment/consultappointment.vue'),
    meta: {
      title: '预约下单',
    },
  },
  {
    name: 'my',
    path: '/my',
    component: () => import('./view/my'),
    meta: {
      title: '我的',
    },
  },{
    name: 'manual',
    path: '/manual',
    component: () => import('./view/my/manual'),
    meta: {
      title: '操作手册',
    },
  },
  {
    name: 'myconsult',
    path: '/myconsult',
    component: () => import('./view/myconsult/myconsult.vue'),
    meta: {
      title: '我的订单',
    },
  }, {
    name: 'masterQRCode',
    path: '/masterQRCode',
    component: () => import('./view/myconsult/masterQRCode.vue'),
    meta: {
      title: '联系咨询师'
    }
  },
  {
    name: 'user',
    path: '/user',
    component: () => import('./view/user'),
    meta: {
      title: '会员中心',
    },
  },
  {
    name: 'cart',
    path: '/cart',
    component: () => import('./view/cart'),
    meta: {
      title: '购物车',
    },
  },
  {
    name: 'goods',
    path: '/goods',
    component: () => import('./view/goods'),
    meta: {
      title: '商品详情',
    },
  },
  {
    name: "login",
    path: '/login',
    component: () => import('./view/goods/login'),
    meta: {
      title: "登录"
    }
  },
  {
    name: "wxlogin",
    path: '/wxlogin',
    component: () => import('./view/wxlogin.vue'),
    meta: {
      title: "微信登录"
    }
  },
  {
    name: "modify",
    path: '/modify',
    component: () => import('./view/modify/modify.vue'),
    meta: {
      title: "修改信息"
    }
  },
  {
    name: "zqtys",
    path: '/zqtys',
    component: () => import('./view/agreement/zqtys.vue'),
    meta: {
      title: "知情同意书"
    }
  },
  {
    name: "xlzxfwxy",
    path: '/xlzxfwxy',
    component: () => import('./view/agreement/xlzxfwxy.vue'),
    meta: {
      title: "心理咨询服务协议"
    }
  }, {
    name: "yszc",
    path: '/yszc',
    component: () => import('./view/agreement/yszc.vue'),
    meta: {
      title: "合一心理隐私政策"
    }
  }, {
    name: "appointManual",
    path: '/appointManual',
    component: () => import('./view/agreement/appointManual.vue'),
    meta: {
      title: "咨询手册"
    }
  }, {
    name: "cancelManual",
    path: '/cancelManual',
    component: () => import('./view/agreement/cancelManual.vue'),
    meta: {
      title: "咨询手册"
    }
  }, {
    name: "makeAppoint",
    path: '/makeAppoint',
    component: () => import('./view/agreement/makeAppoint.vue'),
    meta: {
      title: "咨询手册"
    }
  }, {
    name: "noAuthForPush",
    path: '/noAuthForPush',
    component: () => import('./view/agreement/noAuthForPush.vue'),
    meta: {
      title: "咨询手册"
    }
  }
];

const router = createRouter({
  routes,
  history: createWebHashHistory()
});

router.beforeEach((to, from, next) => {
  const title = to.meta && to.meta.title;
  if (title) {
    document.title = title;
  }
  // chrome
  document.body.scrollTop = 0;
  // firefox
  document.documentElement.scrollTop = 0;
  // safari
  window.pageYOffset = 0;
  loadStudio()

  next();
});

router.afterEach((to, from, next) => {
  window.scrollTo(0, 0);

  loadStudio()
});

export {
  router
};

function loadStudio() {
  let a = localStorage.getItem('com.consult.connectTips');
  if (a != null || a != undefined) {
    return
  }

  let that = this
  axios.get("/api/v1/app/home/studio").then(res => {
    console.log(res);
    if (res.data.ret == 200) {
      let data = res.data.data;
      generationServiceTips(data.worktime, data.hotline, data.qrcode);
    } else {
      console.log('app.vue 接口返回失败:' + data.msg)
    }
  });


  //// TODO: 用户不存首页进入怎么办？
  function generationServiceTips(worktime, hotline, qrcode) {
    let tip1 = "平台保障咨询过程中的隐私和机密性。";
    let tip2 = "在咨询开始时请与咨询师明确讨论和设定具体的咨询目标。";
    let tip3 = "平台和咨询师尊重来访者的文化、价值观和信仰。";
    let tip4 = "来访者可以在公众号中及时关注预约订单状态。"
    let tip5 = "如有其他问题，请及时联系我们。\n工作时间：" + worktime.join("，") + "\n" + "联系电话：" + hotline.join("，");

    let allTips = [tip1, tip2, tip3, tip4, tip5];

    /// 保存联系客服的tips
    localStorage.setItem('com.consult.connectTips', JSON.stringify(allTips))
    /// 保存客服二维码
    localStorage.setItem('com.consult.serviceQRCode', qrcode)
  }
}

